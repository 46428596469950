<template>
  <div class="openClassScoring">
    <el-table :data="scoringTable">
      <el-table-column align="center"
                       prop="manager_name"
                       label="评分人"></el-table-column>
      <el-table-column align="center"
                       prop="opertime"
                       label="时间"></el-table-column>
      <el-table-column align="center"
                       prop="score"
                       label="得分"></el-table-column>
      <!-- <el-table-column align="center"
                       prop="statusStr"
                       label="状态"></el-table-column> -->
    </el-table>
  </div>
</template>

<script>
export default {
  name: "openClassScoring",
  data() {
    return {
      id: this.$route.query.id,
      scoringTable: [],
    };
  },
  created() {
    this.loadScoring();
  },
  methods: {
    loadScoring() {
      this.$post("/base/queryOpenClassCheckList.do", { open_id: this.id })
        .then((res) => {
          this.scoringTable = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
